import { Inject, Injectable, Input } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as UserActions from '@store/user/user.actions';
import { ApiCallService } from '../services/api-call.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { MatDialog } from '@angular/material/dialog';
import { getAuthStatus } from '@app/store/user/user.reducer';
import { FullStoryAnalytics } from '@app/shared/services/fullstory-analytics';
import { defaultRoutes } from '@app/shared/models/shared/landing-page-settings.models';
import { WhatsNewModalComponent } from '@app/shared/components/whats-new-modal/whats-new-modal.component';
import { ApiQueryBuilder } from '../services/api-query-builder';
import { WhatsnewPopupService } from '../services/whatsnew-popup.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
  [x: string]: any;
  @Input() public isAuthenticating: boolean;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canActivate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let value: boolean;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const userPayload = JSON.parse(sessionStorage['okta-token-storage'])?.idToken?.claims?.email.toLocaleLowerCase();
      if(userPayload){
      const payload = { "email": userPayload}
      this.api.constructApiCall(new ApiQueryBuilder().
      addName('login')
      .addBody(payload).build()).subscribe( (data) => {
        this.store.dispatch(new UserActions.SetUser({ user: data['userResponse']}));
        this.store.dispatch(new UserActions.UserAuthenticate(data['userLoginResponse'].login))
        window.localStorage.setItem('userPreferencesData', JSON.stringify(data['userResponse'].pottDetails.userPreferencesObject));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const landing = data['userResponse']?.pottDetails?.userPreferencesObject ? data['userResponse']?.pottDetails?.userPreferencesObject['landingPage'] : 'Container';
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        window.localStorage.setItem('landingPage', landing);
        this.store.pipe(select(getAuthStatus));
        this.handleInitialNavigation();
        value = true;
        const userDetails = data['userResponse'].userDetails;
        if (!data['userResponse'].userDetails.featureSeen) {
          const dialogReference = this.dialog.open(WhatsNewModalComponent, {
            width: '66%',
            height: 'auto',
            disableClose: true,
          });
          dialogReference.afterClosed().subscribe(() => {
            this.whatsnewPopupService.whatsNewpopupClose();
            dialogReference.close();
          });
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.fullstory.identifyUserSession(userDetails.idUser.toString(), userDetails.email);
        this.fullstory.setUserDetails(data);
      },(error) => {
        value = false
        if(error.status == 401 || error.error ){
          window.localStorage.setItem('errorData', JSON.stringify(error));
          localStorage.removeItem('IsLoaded')
        }
      });
      if(value){
        // eslint-disable-next-line no-constant-condition
        return value;
      }
    }
  }   

  private handleInitialNavigation(): void { 
    const history = localStorage.getItem('defaultPage');
    if (history =='/container/overview'){
      const url = this.getPreferredRoute();
      this.router.navigateByUrl(url);
    }
  }

  private getPreferredRoute(): string {
    localStorage.removeItem('defaultPage');
    const preferedPage = window.localStorage.getItem('landingPage');
    return defaultRoutes[preferedPage] || defaultRoutes['default'];  
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private store: Store<{}>,
    private api: ApiCallService,
    private fullstory: FullStoryAnalytics,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private whatsnewPopupService: WhatsnewPopupService,
  ) {}
}
